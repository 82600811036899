.loginButton {
    width: auto;
}

.loginButton+.loginBase {
    display: none;
}

.openLogin+.loginBase,
.dailyPopup {
    display: flex;
}

.etiquetaLogin {
    position: absolute;
    right: 20%;
    width: 80%;
    top: 0;
}

.loginBase,
.dailyPopup {
    z-index: 999;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.90);
    justify-content: center;
    align-items: center;
}

.dailyPopup>div {
    position: relative;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dailyPopup a {
    height: 100%;
}

.dailyPopup img {
    width: 100%;
    max-height: 100%;
}

.containerLoginBase {
    width: 95%;
    height: 90vh;
    margin: auto;
    background-image: url('../../img/backgroundLogin.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    position: relative;
}

.subContainerLoginBase {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
}

.titleLoginRegister h1 {
    color: white;
    font-size: 35px;
    line-height: 38px;
    font-weight: 500 !important;
    font-family: "Avenir Book" !important;
    margin-bottom: 10px !important;
    z-index: 200;
    position: relative;
}

.bold {
    font-family: "Avenir Black";
    font-weight: 900;
}

.titleLoginRegister {
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleLoginRegister h4 {
    font-family: "Avenir Book";
    color: white;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: auto;
}

.containerInformationLogin {
    margin-top: 1rem;
    width: 100%;
}

.containerInformationLogin input {
    border: transparent;
    border-bottom: 2px solid white;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(27px);
    border-radius: 0;
    color: white;
    font-size: 16px;
    padding-left: 10px;
    padding: 6px 0 6px 10px;
    width: 300px;
}


.containerInformationLogin input:focus,
.containerInformationLogin textarea:focus {
    outline: none !important;
    border-bottom: 1px solid #47FFBD;
    background: transparent;
    color: white;
}

.continueLoginButton,
.apiLoginButton {
    background: #47FFBD;
    border: 1px solid #47FFBD;
    color: #16151E;
    cursor: pointer;
    border-radius: 5px;
    padding: 8px 30px;
    font-weight: 900;
    transition: .5s all ease-in-out;
    font-size: 1rem;
}

.apiLoginButton {
    margin-top: 1rem;
}


.continueLoginButton:hover,
.apiLoginButton:hover {
    background: transparent;
    color: #47FFBD;
    border: 1px solid #47FFBD;
}

.registerLoginButton {
    background-color: transparent;
    border: unset;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    margin: 20px 0 !important;
}


.closeLogin {
    background: transparent;
    border: 1px solid white;
    padding: 10px;
    border-radius: 100px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.closeLogin img {
    filter: invert(97%) sepia(23%) saturate(0%) hue-rotate(321deg) brightness(106%) contrast(100%);
}

/* RegisterLogin */
.RegisterLogin {
    padding: 50px 0;
}

.containerEmailLogin input {
    margin-right: 20px;
}

.containerEmailLogin+.registerLoginButton {
    margin-top: 20px;
    margin-bottom: 20px !important;
}

.containerRedesLogin {
    display: flex;
    justify-content: center;
}

.buttonFacebookLogin,
.buttonGoogleLogin {
    border: transparent;
    margin: 0 10px;
    padding: 8px 0;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.buttonFacebookLogin {
    background-color: #004587;
}

.buttonGoogleLogin {
    background-color: #FF434D;
}

.buttonFacebookLogin img,
.buttonGoogleLogin img {
    margin-right: 5px;
}

/* RegisterDetail */
.registerDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.inputContainerRegisterDetail {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
}

.inputContainerRegisterDetail input {
    margin: 0 10px;
    width: 100%;
}

.registerDetail .continueLoginButton {
    margin-top: 1rem;
}


/* Soy */

.soy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 1rem auto 0;
}

.soy button {
    margin-bottom: 20px !important;
    width: 350px;
}

.soy button:last-child {
    margin-bottom: 0 !important;
    width: auto;
}

.soyArtista,
.hagoLoQuePuedo,
.soyDueño {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 100px;
    padding: 10px 20px;
    font-family: "Avenir Book";
    height: 40px;
    font-size: 13px;
    line-height: 12px;
    margin: 0 5px;
    cursor: pointer;
    transition: .5s all ease-in-out;
}

.soyArtista:hover,
.hagoLoQuePuedo:hover,
.soyDueño:hover {
    color: #434249;
    border: 1px solid #47FFBD;
    background-color: #47FFBD;
}

.soyArtista+input,
.hagoLoQuePuedo+input,
.soyDueño+input {
    display: none;
}

.openInputSoyArtista,
.openInputHagoLoQuePuedo,
.openInputSoyDueño {
    color: #434249 !important;
    border: 1px solid #47FFBD;
    background-color: #47FFBD;
    font-weight: 700;
}

#loginBandName.show,
.openInputSoyDueño+input {
    display: block;
    width: 350px;
    margin-bottom: 20px !important;
    font-size: 11px;
    text-align: center;
}

/* TypeEvent */

.newsLetter {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
}

.buttonNewsLetter {
    color: white !important;
    border: 1px solid white;
    background-color: transparent;
    border-radius: 50px;
    font-weight: 900;
    width: 430px !important;
    margin: auto;
    padding: 10px 10px;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.buttonNewsLetter img {
    position: absolute;
    right: 3px;
    top: 2px;
    visibility: hidden;
}

.buttonNewsLetter-click {
    color: #434249 !important;
    border: 1px solid #47FFBD;
    background-color: #47FFBD;
}

.buttonNewsLetter-click img {
    visibility: visible;
}

.newsLetter span {
    font-weight: 400;
    font-size: 10.69px;
    line-height: 15px;
    padding: 20px 0 !important;
    border-bottom: 1px solid white;

}

.selectTypeEventLogin h3 {
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: 20px;
}

.TypeEventLogin {
    margin-top: 20px;
}

.TypeEventLogin button {
    width: 120px;
}

.DetailTypeEventLogin {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.DetailTypeEventLogin button {
    width: fit-content;
    margin-bottom: 10px !important;
}


/* Welcome */
.welcomeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.urlLogin,
.urlLogin div {
    display: flex;
    align-items: flex-start;
}

.urlLogin {
    margin-top: 1rem;
}

.urlLogin a {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 40px;
    margin-right: 15px;
    font-weight: 800;
    font-size: 21px;
    border-radius: 100px;
}

.urlLogin div div,
.urlLogin div div button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}


.urlLogin div div button span {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin-top: 10px;
}

.buttonsWelcome {
    margin-top: 3rem;
    width: 100%;
}

.buttonsWelcome button {
    width: 300px;
    margin: 0 10px;
}

.urlLogin div div button:last-child {
    position: relative;
}

.noCopy p {
    display: none;
}

.copy p {
    display: block;
}

.copy p,
.noCopy p {
    color: #47FFBD;
    background: #16151E;
    padding: 3px;
    font-weight: 900;
    font-size: 10px;
    border-radius: 3px;
    width: 80px;
    position: absolute;
    bottom: -30px;
}


/* Mobile */
@media only screen and (max-width: 700px) {
    .loginBase {
        overflow-y: scroll;
    }

    .containerLoginBase {
        width: 100%;
        height: 100vh;
    }

    .titleLoginRegister h1 {
        font-size: 20px;
        line-height: 20px;
    }

    .titleLoginRegister h4 {
        font-size: 14px;
        line-height: 16px;
    }

    .continueLoginButton {
        width: calc(100% - 30px) !important;
    }

    .apiLoginButton {
        width: calc(100% - 30px) !important;
    }

    .closeLogin {
        top: 60px;
    }

    .DetailTypeEventLogin {
        margin: 10px 0;
    }

    @media (hover:none) {
        .soy button:hover {
            background-color: inherit;
            border: 1px solid white;
            color: white;
        }
    }

    .openInputSoyArtista,
    .openInputHagoLoQuePuedo,
    .openInputSoyDueño {
        color: #434249 !important;
        border: 1px solid #47FFBD !important;
        background-color: #47FFBD !important;
        font-weight: 700;
    }

    .soy .button-click {
        color: #434249 !important;
        background-color: #47FFBD !important;
        border: 1px solid #47FFBD !important;
    }

    /* RegisterLogin */
    .RegisterLogin {
        padding: 20px 0;
    }

    .containerEmailLogin input {
        margin-right: 0;
        margin-bottom: 20px !important;
        width: 100%;
    }

    /* registerDetail */
    .inputContainerRegisterDetail {
        flex-direction: column;
    }

    .inputContainerRegisterDetail {
        margin-bottom: 0;
    }

    .inputContainerRegisterDetail input {
        margin: auto auto 10px !important;
    }

    /* typeEventLogin */
    .TypeEventLogin {
        margin-top: 0;
        overflow-y: scroll;
    }

    .newsLetter span {
        padding: 10px 0 !important;
    }

    .buttonNewsLetter {
        width: 100% !important;
        font-size: 12px !important;
        padding: 0px 10px !important;
        height: 37px !important;
    }

    .selectTypeEventLogin h3 {
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px;
        margin-bottom: 10px !important;
    }

    .buttonNewsLetter img {
        right: 2px;
        top: 2px;
        width: 32px;
    }

    .TypeEventLogin:last-child button,
    .DetailTypeEventLogin button {
        margin-bottom: 5px !important;
        width: 100px;
        font-size: 12px;
        padding: 0 15px;
        height: 30px;
        line-height: 10px;
    }

    .DetailTypeEventLogin button {
        width: auto !important;
    }

    /* welcome */
    .urlLogin {
        flex-direction: column;
        align-items: center;
    }

    .urlLogin a {
        margin-bottom: 1rem !important;
        font-size: 15px;
    }

    .buttonsWelcome {
        margin-top: 2rem;
    }

    .buttonsWelcome .continueLoginButton:first-child {
        margin-bottom: 10px !important;
    }

    .urlLogin div div {
        flex-direction: unset;
        align-items: center;
    }

    .urlLogin div div span {
        margin-top: 0;
    }


    .dailyPopup>div {
        width: 90%;
        height: auto;
    }
}